




























import { Component, Vue } from 'vue-property-decorator'
import PageLayout from '@/components/common/page-layout/PageLayout.vue'
import ContentArea from '@/components/common/ContentArea.vue'
import PrimaryButton from '@/components/common/PrimaryButton.vue'
import Title from '@/components/common/page-layout/Title.vue'
import SelectBox from '@/components/SelectBox.vue'
interface ILink {
  label: string
  path: string
}

@Component({
  components: {
    PageLayout,
    ContentArea,
    PrimaryButton,
    Title,
    SelectBox
  }
})

export default class TermsPolicy extends Vue {
  $refs!: { selectBox: SelectBox }
  private selected = false
  private otpVerified = false
  private checkData = [
    {
      text: this.$t('login.termsPolicy.checkBox'),
      value: this.$t('login.termsPolicy.checkBox')
    }
  ]

  private created (): void {
    if (window.innerWidth < 767) {
      this.$root.$on('mobile-go-back', () => {
        this.goBack()
      })
    }
  }

  private goBack (): void {
    this.$router.go(-1)
  }

  private mounted (): void {
    this.otpVerified = this.$store.getters['auth/getOtpVerified']
    const a = sessionStorage.getItem('isAgree')
    if (a) {
      this.selected = true
      this.$refs.selectBox.setVal([a])
    } else {
      this.selected = false
      this.$refs.selectBox.setVal([])
    }
  }

  private updateVal (item:string): void {
    this.selected = !!item.length
    sessionStorage.setItem('isAgree', item)
    if (this.selected && this.otpVerified) {
      this.$store.dispatch('auth/setIsAuth', true)
    } else {
      this.$store.dispatch('auth/setIsAuth', false)
    }
  }

  private goTo (item: ILink): void {
    this.$router.push(item.path)
  }

  private submit (): void {
    this.$router.push('signup/success')
  }
}
